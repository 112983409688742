<template>
  <div class="goTop">
    <el-backtop target=".goTop">
      <i class="el-icon-caret-top"></i>
    </el-backtop>
    <Header :title="$t('message.link.preface')" />
    <div class="preBg">
      <div class="cont">
        <img :src="setting.imgs.line" class="line" width="100%" />
        <div class="head head1" @click="handleJump(setting.head1.link)">
          <img :src="setting.head1.avatar" width="85px" />
          <div>{{ setting.head1.title }}</div>
        </div>
        <div class="head head2" @click="handleJump(setting.head2.link)">
          <img :src="setting.head2.avatar" width="85px" />
          <div>{{ setting.head2.title }}</div>
        </div>
        <div class="head head3" @click="handleJump(setting.head3.link)">
          <img :src="setting.head3.avatar" width="85px" />
          <div>{{ setting.head3.title }}</div>
        </div>
        <div class="head head4" @click="handleJump(setting.head4.link)">
          <img :src="setting.head4.avatar" width="85px" />
          <div>{{ setting.head4.title }}</div>
        </div>
        <div class="head head5" @click="handleJump(setting.head5.link)">
          <img :src="setting.head5.avatar" width="85px" />
          <div>{{ setting.head5.title }}</div>
        </div>
        <div class="head head6" @click="handleJump(setting.head6.link)">
          <img :src="setting.head6.avatar" width="85px" />
          <div>{{ setting.head6.title }}</div>
        </div>
        <div class="head head7" @click="handleJump(setting.head7.link)">
          <img :src="setting.head7.avatar" width="85px" />
          <div>{{ setting.head7.title }}</div>
        </div>
      </div>
      <div class="artical">
        <div class="pic">
          <div class="row" style="justify-content: space-between">
            <div
              class="col col-12"
              @mouseover="show = 'pic1'"
              @mouseout="show = ''"
              @click="handleJump(setting.head1.link)"
            >
              <img :src="setting.picItem1.pic1" width="100%" />
              <div class="read" v-show="show === 'pic1'">
                <span>{{ setting.picItem1.title }}</span>
                <br />
                <span>{{ setting.picItem1.des }}</span>
              </div>
            </div>

            <div
              class="col col-4"
              @mouseover="show = 'pic2'"
              @mouseout="show = ''"
              @click="handleJump(setting.head2.link)"
            >
              <img :src="setting.picItem2.pic2" width="100%" />
              <div class="read" v-show="show === 'pic2'">
                <span>{{ setting.picItem2.title }}</span>
                <br />
                <span>{{ setting.picItem2.des }}</span>
              </div>
            </div>
            <div
              class="col col-4"
              @mouseover="show = 'pic3'"
              @mouseout="show = ''"
              @click="handleJump(setting.head3.link)"
            >
              <img :src="setting.picItem3.pic3" width="100%" />
              <div class="read" v-show="show === 'pic3'">
                <span>{{ setting.picItem3.title }}</span>
                <br />
                <span>{{ setting.picItem3.des }}</span>
              </div>
            </div>
            <div
              class="col col-4"
              @mouseover="show = 'pic4'"
              @mouseout="show = ''"
              @click="handleJump(setting.head4.link)"
            >
              <img :src="setting.picItem4.pic4" width="100%" />
              <div class="read" v-show="show === 'pic4'">
                <span>{{ setting.picItem4.title }}</span>
                <br />
                <span>{{ setting.picItem4.des }}</span>
              </div>
            </div>
            <div
              class="col col-4"
              @mouseover="show = 'pic5'"
              @mouseout="show = ''"
              @click="handleJump(setting.head5.link)"
            >
              <img :src="setting.picItem5.pic5" width="100%" />
              <div class="read" v-show="show === 'pic5'">
                <span>{{ setting.picItem5.title }}</span>
                <br />
                <span>{{ setting.picItem5.des }}</span>
              </div>
            </div>
            <div
              class="col col-4"
              @mouseover="show = 'pic6'"
              @mouseout="show = ''"
              @click="handleJump(setting.head6.link)"
            >
              <img :src="setting.picItem6.pic6" width="100%" />
              <div class="read" v-show="show === 'pic6'">
                <span>{{ setting.picItem6.title }}</span>
                <br />
                <span>{{ setting.picItem6.des }}</span>
              </div>
            </div>
            <div
              class="col col-4"
              @mouseover="show = 'pic7'"
              @mouseout="show = ''"
              @click="handleJump(setting.head7.link)"
            >
              <img :src="setting.picItem7.pic7" width="100%" />
              <div class="read" v-show="show === 'pic7'">
                <span>{{ setting.picItem7.title }}</span>
                <br />
                <span>{{ setting.picItem7.des }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="text">
          <div class="title">{{ setting.article.title }}</div>
          <div class="text-cont">{{ setting.article.des1 }}</div>
          <div class="text-cont">{{ setting.article.des2 }}</div>
        </div>
      </div>
    </div>
    <!-- <img
      :src="setting.imgs.bg"
      width="100%"
      style="display: block; margin-bottom: -1450px"
    /> -->

    <Footer />
    <a
      href="http://www.beian.gov.cn/portal/index?token=aa903a2b-262f-41b6-9a91-2ccccb1c732f"
      class="ip-link"
      target="_blank"
      >陕ICP备20012594号-2</a
    >

    <!-- <h1 style="text-align: center; padding-bottom: 8px; color: #8b8b8b">
      Посете：30200
    </h1> -->
  </div>
</template>

<script>
import Header from "../../components/header"
import Footer from "../../components/footer"
export default {
  name: "Preface",
  components: {
    Header,
    Footer,
  },
  data () {
    return {
      setting: {
        head1: {
          avatar: 'http://cdn.topartsintermational.com/ebbs.png',
          title: this.$t('message.link.history'),
          link: '/history',
        },
        head2: {
          avatar: 'http://cdn.topartsintermational.com/past.png',
          title: this.$t('message.link.warriors'),
          link: '/warriors',
        },
        head3: {
          avatar: 'http://cdn.topartsintermational.com/ancient.png',
          title: this.$t('message.link.ancient'),
          link: '/ancient',
        },
        head4: {
          avatar: 'http://cdn.topartsintermational.com/originality.png',
          title: this.$t('message.link.culture'),
          link: '/culture',
        },
        head5: {
          avatar: 'http://cdn.topartsintermational.com/visiting.png',
          title: this.$t('message.link.visit'),
          link: '/visit',
        },
        head6: {
          avatar: 'http://cdn.topartsintermational.com/creation.png',
          title: this.$t('message.link.creation'),
          link: '/creation',
        },
        head7: {
          avatar: 'http://cdn.topartsintermational.com/unusual.png',
          title: this.$t('message.link.game'),
          link: '/game',
        },

        picItem1: {
          pic1: 'http://cdn.topartsintermational.com/pic1-s.jpg',
          title: '历史绵迭',
          des: this.$t('message.link.history')
        },
        picItem2: {
          pic2: 'http://cdn.topartsintermational.com/pic2.png',
          title: '览古观今',
          des: this.$t('message.link.warriors')
        },
        picItem3: {
          pic3: 'http://cdn.topartsintermational.com/pic3.png',
          title: '古法艺作',
          des: this.$t('message.link.ancient')
        },
        picItem4: {
          pic4: 'http://cdn.topartsintermational.com/pic4.png',
          title: '中西匠心',
          des: this.$t('message.link.culture')
        },
        picItem5: {
          pic5: 'http://cdn.topartsintermational.com/pic5.png',
          title: '途秦欲旅',
          des: this.$t('message.link.visit')
        },
        picItem6: {
          pic6: 'http://cdn.topartsintermational.com/pic6.png',
          title: '访古出新',
          des: this.$t('message.link.creation')
        },
        picItem7: {
          pic7: 'http://cdn.topartsintermational.com/pic7.png',
          title: '奇思律动',
          des: this.$t('message.link.game')
        },
        imgs: {
          bg: 'http://cdn.topartsintermational.com/prefaceBg-se.jpg',
          line: 'http://cdn.topartsintermational.com/line.png',
        },
        article: {
          title: this.$t('message.link.preface'),
          des1: this.$t('message.preface.des1'),
          des2: this.$t('message.preface.des2'),
        }
      },
      show: "",
    }
  },
  methods: {
    handleJump (url) {
      if (url === this.$route.path) return
      this.$router.push(url)
    }
  },
};
</script>

<style scoped lang="less">
.goTop {
  min-width: 1240px;
  height: 100vh;
  overflow-x: hidden;
  position: relative;
  background: #012545;
}
.preBg {
  width: 100%;
  padding-top: 650px;
  padding-bottom: 36px;
  background: url("http://cdn.topartsintermational.com/prefaceBg-se.jpg")
    no-repeat;
  background-size: cover;
}
.cont {
  height: 380px;
  position: relative;
  display: flex;
}
.line {
  margin-top: 100px;
  display: block;
  height: 169px;
}
.head {
  position: absolute;
  font-size: 14px;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
.head1 {
  top: 15%;
  left: 3%;
}
.head2 {
  top: 49%;
  left: 7%;
}
.head3 {
  top: 20%;
  left: 25%;
}
.head4 {
  top: 35%;
  left: 35%;
}
.head5 {
  top: 40%;
  left: 55%;
}
.head6 {
  top: 27%;
  left: 63%;
}
.head7 {
  top: 17%;
  left: 79%;
}
.artical {
  width: 1200px;
  margin: auto;
  display: flex;
  background-color: #a60009;
}
.pic {
  width: 50%;
  background: #fff;
  padding: 30px 25px;
}
.col {
  position: relative;
  cursor: pointer;
}
.col-4 {
  margin-top: 24px;
}
.read {
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 12px;
  color: #fff;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding-left: 5px;
  padding-top: 2px;
  box-sizing: border-box;
  z-index: 1;
}
.text {
  width: 50%;
  box-sizing: border-box;
  color: #fff;
  padding-left: 40px;
  line-height: 1.6;
  letter-spacing: 0.4px;
}
.title {
  font-weight: 600;
  font-size: 18px;
}
.text-cont {
  font-size: 14px;
  text-indent: 18px;
}
.ip-link {
  color: #fff;
  text-align: center;
  display: block;
  padding-bottom: 24px;
}
</style>